@mixin font-face ($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
	@font-face {
		font-family: $fontname;
		src: url('../../../fonts/' + $fontfile + '.eot');
		src: url('../../../fonts/' + $fontfile + '.eot?#iefix') format('embedded-opentype'),
			 url('../../../fonts/' + $fontfile + '.woff') format('woff'),
			 url('../../../fonts/' + $fontfile + '.ttf') format('truetype'),
			 url('../../../fonts/' + $fontfile + '.svg##{fontname}') format('svg');
		font-weight: $fontweight;
		font-style: $fontstyle;
	}
};

@include font-face('OpenSans', 'opensans/OpenSans-Light', 300);
@include font-face('OpenSans', 'opensans/OpenSans-Regular', 400);
@include font-face('OpenSans', 'opensans/OpenSans-SemiBold', 600);
@include font-face('OpenSans', 'opensans/OpenSans-Bold', 700);
@include font-face('OpenSans', 'opensans/OpenSans-ExtraBold', 800);


@mixin diploma-font-face ($fontname, $fontfile) {
	@font-face {
		font-family: $fontname;
		src: url('../../../fonts_gramota/' + $fontfile + '.ttf') format('truetype');
	}
};

@include diploma-font-face('Arial', 'Arial/Arial');
@include diploma-font-face('Arial_Black', 'Arial_Black/Arial_Black');
@include diploma-font-face('Book_Antiqua', 'Book_Antiqua/Book_Antiqua');