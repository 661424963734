.c-multiselect{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 26rem;
    margin-bottom: 3rem;
    position: relative;
    &__wrapper{
        position: relative;
        &:hover .c-multiselect__options {
            opacity: 1;
            visibility: visible;
        }
    }
    &__trigger {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0.85rem 1rem;
        font-size: 1.4rem;
        cursor: pointer;
        border: 1px solid #3E4B5D;
    }
    &__options {
        position: absolute;
        margin-top: 0.6rem;
        display: block;
        top: 100%;
        left: 0;
        right: 0;
        border: 1px solid #3E4B5D;
        border-bottom: 0;
        -webkit-filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
        filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
        background: #fff;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        opacity: 0;
        visibility: hidden;
        z-index: 4;
        max-height: 40rem;
        overflow-y: auto;
    }
    &__options.open {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
    &__options li ul li .c-multiselect__option {
        padding-left: 4rem;
    }
    &__options .e-checkbox {
        position: absolute;
        right: 0;
        top: 1.2rem;
        right: 1.4rem;
    }
    &__option {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid #3E4B5D;
        cursor: pointer;
        position: relative;
        & label {
            cursor: pointer;
            padding: 0 0.5rem;
        }
    }
    &__arrow {
        position: relative;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 1.2rem;
    }
    &.open .c-select__arrow {
        -webkit-transform: rotateX(-180deg);
        transform: rotateX(-180deg);
    }
}