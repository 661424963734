.e-minmax {
	position: relative;
	border: 1px solid $oxford-blue;
	outline: none;
	box-shadow: none !important;
	border-radius: 0;
	padding: 0.85rem 1rem;
	min-width: 6rem;
	width: 38%;
	font-size: 1.4rem;
	-webkit-appearance: none !important;
  	-moz-appearance: none !important;
	appearance: none !important;
	background-clip: padding-box;
	@include media-breakpoint-down(sm) {
		min-width: 100%;
	}
	@media screen and (-webkit-min-device-pixel-ratio:0) { 
		font-size: 1.4rem;
	}
	&:hover {
		cursor: pointer;
	}
	&:focus {
		font-size: 1.4rem;
	}
	&::placeholder {
		color: $cadet-blue;
	}
	&-filter{
		background-color: transparent;
		border: 1px solid #B0BAC3;
		max-width: 90px;
	}
	&-autocomplete {
		position: relative;
		min-width: 260px;
		&__items {
			position: absolute;
			border: 1px solid $oxford-blue;
			border-top: 0;
			z-index: 99;
			top: 100%;
			left: 0;
			right: 0;
			max-height: 30rem;
			overflow-y: auto;
			div {
				padding: 0.85rem 1rem;
				color: $woodsmoke;
				cursor: pointer;
				transition: all 0.3s ease;
				border-bottom: 1px solid $oxford-blue;
				background-color: $white;
				&:last-child {
					border-bottom: 0;
				}
				&:hover {
					color: lighten($color: $woodsmoke, $amount: 40%);
				}
			}
		}
	}
	&-file {
		input {
			display: none;
		}
		label {
			cursor: pointer;
		}
	}
}

.e-minmax[type='date'] {
	height: 40px;
	font-size: 12px;
}
.e-minmax[type='date']::-webkit-calendar-picker-indicator {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
	&:hover {
		cursor: pointer;
	}
}