.modal {
  display: block;
  overflow-y: scroll;

  &::after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.8;
    background: #3e4b5d;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }
}
.modal-backdrop.show {
  opacity: 0.8;
  background: $oxford-blue;
}
.modal__title {
  font-size: 1.8rem;
  line-height: 2.5rem;
  font-weight: bold;
  text-align: center;
}
.modal__subtitle {
  margin-top: 1.5rem;
  &--tac {
    text-align: center;
  }
}
.modal__close{
  position: absolute;
  right: -3.5rem;
  top: -3.5rem;
  display: flex;
  @include media-breakpoint-down(sm) {
    right: 0;
  }
  &:hover {
    .icon-close {
      filter: brightness(0.7);
    }
  }
  .icon-close {
    font-size: 2.4rem;
    transition: all 0.3s ease;
  }
}
.modal-content{
  border: none;
  border-radius: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-bottom: 50px;
}
.modal-body {
  padding: 0;
}
.modal-body__video {
  height: 500px;
}
.modal-dialog{
  max-width: 60rem;
  margin: 0 auto; 
  padding: 0 1rem;
  @include media-breakpoint-down(lg) {
    max-width: 58rem;
  }
  @include media-breakpoint-down(md) {
    max-width: 72rem;
  }
  @include media-breakpoint-down(sm) {
    max-width: 54rem;
  }
  @include media-breakpoint-down(xs) {
    max-width: 100%;
  }
}

.modal-img{
  &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &.modal-dialog {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content; }
} 

.modal-dialog_how-to {
  max-width: 75rem;
}

.modal-dialog_template-form {
  max-width: 66rem;
}
