.c-admin-panel {
    &_wrapper {
        margin-top: 2rem; 
    }
    &-user {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #EFF2F5;
        padding: 1.5rem 2rem 1.5rem 1.5rem;
        position: relative;
        margin-bottom: 3rem; 
        @include media-breakpoint-down(sm) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start; 
        } 
    }
    &-user__change-img {
        height: 9rem;
        width: 9rem;
        border: 2px solid #3E4B5D;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-negative: 0;
        flex-shrink: 0; 
        @include media-breakpoint-down(sm){
            height: 7rem;
            width: 7rem; 
        } 
    }
    &-user__change-img .icon-photo {
        font-size: 2.5rem; 
    }
    &-user__empty-img {
        height: 8rem;
        width: 8rem;
        border-radius: 50%;
        background-color: #D9DEE2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; 
        @include media-breakpoint-down(sm) {
            height: 6rem;
            width: 6rem; 
        } 
    }
    &-user__desc {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 1.5rem; 
        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-top: 1.5rem; 
        } 
    }
    &-user__name {
        font-size: 1.6rem;
        font-weight: bold; 
    }
    &-user__section {
        color: #787C82;
    }
    &-user__nomination {
        position: absolute;
        top: 68%;
        left: 68%;
        cursor: pointer; 
    }
    &-user__nomination:hover 
    .c-admin-panel-user__nomination-wrap {
        opacity: 1;
        visibility: visible; 
    }
    &-user__nomination .icon {
        font-size: 3.3rem; 
    }
    &-user__nomination-wrap {
        background-color: #fff;
        position: relative;
        padding: 1rem;
        font-size: 1.2rem;
        width: 26rem;
        z-index: 6;
        -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        margin-top: 1rem;
        margin-left: -2.3rem;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; 
    }
    &-user__nomination-wrap:after {
        content: '';
        top: -1rem;
        left: 3rem;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ffffff transparent; 
        @include media-breakpoint-down(sm){
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 2rem; 
        } 
    }
    &__btn.e-btn {
        margin-bottom: 1rem;
        display: none;
        height: 5rem; 
        @include media-breakpoint-down(sm) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex; 
        }        
    }
    &__btn-menu.e-btn {
        margin-bottom: 2rem;
        display: none;
        width: 100%; 
        @include media-breakpoint-down(sm) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex; 
        } 
    }
    &__subtitle {
        font-size: 1.4rem;
        font-weight: bold;
        color: #15171A;
        margin-bottom: 1.5rem; 
    }
    &__subtitle-gray {
        font-size: 1.4rem;
        font-weight: bold;
        color: #B0BAC4;
        margin-bottom: 1.5rem;
    }
    &__subtitle-blue {
        font-size: 1.4rem;
        font-weight: bold;
        color: #3E4B5D;
        margin-bottom: 1.5rem;
    }
    &-form {
        margin-bottom: 2rem;
        background-color: #EFF2F5;
        padding: 2rem 2rem 3.8rem 2rem; 
    }
    &-form__row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; 
        @include media-breakpoint-down(sm) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            @include media-breakpoint-down(sm) {
                -webkit-box-orient: vertical;
                -webkit-box-direction: reverse;
                -ms-flex-direction: column-reverse;
                flex-direction: column-reverse; 
            } 
        }
           
        } 
    &-form__col {
        width: 50%; 
        @include media-breakpoint-down(sm) {
            width: 100%; 
        } 
    }
    &-form__col:not(:last-child) {
        margin-right: 2rem; 
        @include media-breakpoint-down(sm) {
            margin-right: 0; 
        } 
    }
    &-form .form-group {
        margin-bottom: 2rem; 
    }
    &-form .e-input, .c-admin-panel-form .e-textarea {
        background: transparent;
        border-color: #B0BAC4;
        min-width: auto; 
        background-color: transparent;
    }
    &-form .c-select, .c-admin-panel-form .c-multiselect {
        min-width: auto;
        width: 100%;
        background-color: transparent;
        //margin-bottom: 2rem; 
    }    
    &-form .e-textarea {
        border: 1px solid #B0BAC4;
        background-color: transparent;
        min-width: auto; 
    }
    &-form .e-input, .c-settings-form .c-select__trigger .autocomplete-wrapper input {
        border: 1px solid #B0BAC4;
        background-color: transparent;
        min-width: auto; 
    }
    &-form .autocomplete-wrapper input {
        border: 1px solid #B0BAC4;
        background-color: transparent;
        min-width: auto; 
    }
    &-form .css-uhflfn-Control{
        border: 1px solid #B0BAC4;
        background-color: transparent;
        min-width: auto; 
    }
    &-form .e-btn {
        min-width: auto;
        width: 100%; 
    }
    &-form .c-select__trigger, .c-admin-panel-form .c-multiselect__trigger {
        border-color: #B0BAC4; 
        background-color: transparent;
    }
    &-form .c-select__options {
        background-color: #EFF2F5;
        border-left: 1px solid #B0BAC4;
        border-right: 1px solid #B0BAC4;
        border-bottom: none; 
    }
    &-form .c-select__option {
        background-color: #EFF2F5;
        border-bottom: 1px solid #B0BAC4; 
        @include media-breakpoint-down(sm){
          margin-bottom: 2rem; 
        } 
    }
    &-form .c-multiselect__option {
        background-color: #EFF2F5;
        border-bottom: 1px solid #B0BAC4; 
    }
    &-form .c-multiselect__options {
        background-color: #EFF2F5;
        border-left: 1px solid #B0BAC4;
        border-right: 1px solid #B0BAC4;
        border-top: 1px solid #B0BAC4; 
    }
    &-form .e-input-autocomplete__items {
        border-left: 1px solid #B0BAC4;
        border-right: 1px solid #B0BAC4;
        border-bottom: 1px solid #B0BAC4; 
    }
    &-form .e-input-autocomplete__items div {
        background-color: #EFF2F5;
        border-bottom: 1px solid #B0BAC4; 
    }
    &-form .e-textarea {
        height: 100%; 
    }
    &-form .admin-info {
        height: 100%; 
    }
    &-form .admin-info .form-control {
        height: 100%; 
    }
    &-docs__wrapper {
        background-color: #EFF2F5;
        padding: 2rem; 
        margin-bottom: 2rem;
    }
    &-docs__list {
        margin-top: 1rem; 
        margin-bottom: 2rem;
    }
    &-docs__item {
        padding: 0.5rem 0.8rem 0.5rem 1.2rem;
        background-color: #D9DEE2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; 
    }
    &-docs__item:not(:last-child) {
        margin-bottom: 0.6rem; 
    }
    &-docs__name {
        font-weight: 600;
        font-size: 1.2rem; 
    }   
    &__btn-invite.e-btn {
        margin-right: 0;
        padding: 0.5rem 1.5rem 0.5rem 3.2rem;
        min-width: 13rem; 
        @include media-breakpoint-down(sm){
            margin-top: 2rem;
            margin-left: auto; 
        } 
    } 
    &__btn-add-result.e-btn {
        margin-right: 0;
        padding: 0.5rem 1.5rem 0.5rem 3.2rem;
        min-width: 18rem; 
        @include media-breakpoint-down(sm){
            margin-top: 2rem;
            margin-left: auto; 
        } 
    } 
    &__btn-add-adm.e-btn {
        margin-right: 0;
        margin-bottom: 1rem;
        margin-left: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0.5rem 1.5rem 0.5rem 3.2rem;
        min-width: 13rem; 
        @include media-breakpoint-down(sm){
            margin-top: 2rem;
            margin-left: auto; 
        } 
    }
    &__nav-apart-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; 
        margin-bottom: 2rem;
        @include media-breakpoint-down(sm) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column; 
        } 
    }
    &__empty-request {
        margin-bottom: 1rem; 
    }
    &__events .c-admin-panel__btn-invite, .c-admin-panel__notify .c-admin-panel__btn-invite {
        margin-top: 2rem;
        margin-left: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; 
    }
}
      
.c-admin-panel__events .c-admin-panel__btn-invite, .c-admin-panel__notify .c-admin-panel__btn-invite {
  margin-top: 2rem;
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

    


