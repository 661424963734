.c-modal-add-news{
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__wrapper {
        padding: 5.5rem 5rem 8rem;
        @include media-breakpoint-down(sm){
            padding: 4rem;
        }
        @include media-breakpoint-down(xs){
            padding: 2rem;
        }
    }
    &__form {
        margin-top: 3rem;
        & .e-textarea {
            min-height: 18rem;
        }
    }
    &__btn {
        margin-top: 3rem;
        @include media-breakpoint-down(sm){
            width: 100%;
        }
    }
    &__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -0.6rem 1.7rem -0.6rem; 
        @include media-breakpoint-down(sm) {
            margin: 0 0 1.7rem 0;
        }
    }
    &-item {
        width: 6rem;
        height: 4rem;
        overflow: hidden;
        position: relative;
        background-color: #D9DEE2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0.3rem 0.4rem;
        & .icon-photo {
            font-size: 2.5rem; }
        &__btn {
            position: absolute;
            top: 3px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            right: 3px;
            
        }
    }
    &__docs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 1rem;
    }
    &__doc {
        background-color: #D9DEE2;
        padding: 0.7rem 0.8rem 0.7rem 1.2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        cursor: pointer;
        &:not(:last-child) {
            margin-bottom: 0.6rem; }
        &-name {
            font-weight: 600;
            font-size: 1.2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 1rem;
            color: #15171A; }
    }
    &__video {
        background-color: #15171A;
        border-radius: 50%;
        min-width: 25px;
        position: relative;
        height: 25px;
        cursor: pointer;
        opacity: 0.6;
        text-align: center;
        left: 50px;
        svg {
            margin-top: 1px;
        }
    }
    .e-input-autocomplete{
        margin-bottom: 30px;
        min-width: 46rem;
        @include media-breakpoint-down(sm) {
            min-width: 0;
            max-width: 100%;
        }
    }

    .e-attach-document span{
        cursor: pointer;
        color: #3E4B5D;
        margin-left: 5px;
    }

    .e-textarea{
        min-width: 46rem;
        @include media-breakpoint-down(sm) {
            min-width: 0;
        }
    }
}