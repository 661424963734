.c-modal-change-pass{
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__wrapper {
        padding: 4.5rem 16rem 9rem;
        @include media-breakpoint-down(md){
            padding: 4rem;
        }
        @include media-breakpoint-down(sm){
            padding: 2rem;
        }
    }
    &__form{
        margin-top: 4rem;
        @include media-breakpoint-down(md){
            .e-btn--md{
                width: 100%;
                max-width: 100%;
                min-width: auto;
            }
        }
    }
}