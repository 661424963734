.c-modal-file-load {
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__wrapper {
        max-width: 35rem;
        margin: 0 auto;
        padding: 8.5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__file {
        margin-top: 3rem;
    }
    &__info {
        margin-top: 1rem;
    }
}
