.c-all-events {
    &__wrapper {
        padding-top: 1.5rem; 
    }
    &__child-filter {
        margin-top: 3rem; 
    }
    &__items {
        margin-top: 1rem; 
    }
    &__empty .c-empty-block {
        padding: 3.3rem 6.5rem;
     }
    &__empty .c-empty-block__text {
        text-align: center; 
    }
}