.c-community-page {
    &__wrapper {
      margin-top: 2rem;
    }
    &-user {
      display: flex;
      align-items: center;
      background-color: $athens-gray;
      padding: 2rem 2rem 2rem 1.5rem;
      position: relative;
      margin-bottom: 2rem;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
      }
      &__change-img {
        height: 9rem;
        width: 9rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        position: relative;
        border: 2px solid #3E4B5D;
        //    background-color: #D9DEE2;
        @include media-breakpoint-down(sm) {
          height: 7rem;
          width: 7rem;
        }
        & img{
          border-radius: 50%;
          height: 8rem;
          width: 8rem;
          @include media-breakpoint-down(sm) {
                height: 6rem;
                width: 6rem; 
            } 
        }
      }
      &__empty-img {
        height: 9rem;
        width: 9rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        position: relative;
        background-color: #D9DEE2;
        //background-image: url(./../../../images/tmp_file/event.png);
        //background-size: cover;
        //background-repeat: no-repeat;
        //border: 2px solid #3E4B5D;
        @include media-breakpoint-down(sm) {
          height: 6rem;
          width: 6rem;
        }
      }
      &__desc {
        display: flex;
        flex-direction: column;
        margin-left: 1.5rem;
        @include media-breakpoint-down(sm) {
          margin-left: 0;
          margin-top: 1.5rem;
        }
      }
      &__name {
        font-size: 1.6rem;
        font-weight: bold;
      }
      &__section {
        color: $rolling-stone;
      }
      &__nomination {
        position: absolute;
        top: 68%;
        left: 68%;
        cursor: pointer;
        &:hover {
          .c-community-page-user__nomination-wrap {
            opacity: 1;
            visibility: visible;
            display: inherit;
          }
        }
        .icon {
          font-size: 3.3rem;
        }
        &-small{
          .icon {
            font-size: 2.3rem;
          }
        }
        &-wrap {
          background-color: #fff;
          position: relative;
          padding: 1rem;
          font-size: 1.2rem;
          width: 26rem;
          z-index: 6;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
          margin-top: 1rem;
          margin-left: -2.3rem;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s ease;
          display: none;
          &:after {
            content: '';
            top: -1rem;
            left: 3rem;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #ffffff transparent;            
          }
        }
      }
      .c-edit__wrapper {
        @include media-breakpoint-down(sm) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 2rem;
        }
      }
    }
    &__nav {
      margin-bottom: 2rem;
      &.e-nav-line .tab-content {
        margin-top: 0.1rem;
      }
      &.e-nav-line .tab-content.mt20 {
        margin-top: 2rem;
      }
      &.e-nav-line .tab-pane {
        background-color: $athens-gray;
        padding: 1.7rem 2rem;
        min-height: 9rem;
      }
      &.e-nav-line .tab-pane.bg-white{
        background-color: $white;
      }
      &.e-nav-line .tab-pane.no-padding{
        padding: 0;
      }
    }
    &__submenu {
      display: flex;
      & a{
        margin-right: 12px;
      }
    }
    &__tab-info {
      max-height: auto;
      @include media-breakpoint-down(xs) {
        max-height: none;
      }
    }
    &__tab-address{
      display: flex;
      align-items: center;
      padding-bottom: 4rem;
      .icon {
        fill: transparent;
        stroke-width: 1;
        margin-right: 1.3rem;
      }
    }
    &__modals {
      margin-bottom: 2rem;
      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
      }
      .e-btn--plus {
        @include media-breakpoint-down(md) {
          width: 100%;
          margin-bottom: 1rem;
          margin-right: 0;
          max-width: 100%;
          justify-content: flex-start;
        }
      }
    }
    &-news{
      margin-top: 2.5rem;
      &__title {
        font-size: 1.4rem;
      }
      &__list {
        margin-top: 1.6rem;
      }
    }
    &__btn.e-btn{
      margin-bottom: 1rem;
      display: none;
      height: 5rem;
      @include media-breakpoint-down(md) {
        display: flex;
      }
    }
    &__btn-menu.e-btn{
      margin-bottom: 2rem;
      display: none;
      width: 100%;
      @include media-breakpoint-down(md) {
        display: flex;
      }
    }
    &__title {
      font-size: 1.4rem;
      margin: 3rem 0 1.5rem 0;
    }
  }
