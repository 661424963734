.c-protocol {
  padding-top: 2rem;
  margin-bottom: 220px;
  &__all-back {
    color: #b0bac4;
    font-size: 1.4rem;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    &:hover {
      text-decoration: none;
      color: #b0bac4;
    }
    & span {
      margin-left: 1rem;
    }
  }
  &__back-to {
    color: #b0bac4;
    transition: all 0.2s ease;
  }
  &__back-to:hover {
    text-decoration: none;
    opacity: 0.5;
    transition: all 0.2s ease;
  }
  &__wrapper {
    padding-top: 2.4rem;
  }
  &-user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #eff2f5;
    padding: 2rem 5rem 2rem 2rem;
    position: relative;
    margin-bottom: 2rem;
    @include media-breakpoint-down(sm) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    &__change-img {
      height: 8rem;
      width: 8rem;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      position: relative;
      @include media-breakpoint-down(sm) {
        height: 7rem;
        width: 7rem;
      }
    }
    &__empty-img {
      height: 8rem;
      width: 8rem;
      //background-image: url('../images/tmp_file/event2.png');
      background-size: cover;
      background-repeat: no-repeat;
      @include media-breakpoint-down(sm) {
        height: 6rem;
        width: 6rem;
      }
    }
    &__desc {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-left: 1.5rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-top: 1.5rem;
      }
    }
    &__name {
      font-size: 1.6rem;
      font-weight: bold;
    }
    &__section {
      color: #787c82;
    }
    & .c-edit__wrapper {
      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 4rem;
        right: 2rem;
      }
    }
    &__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-top: 1.2rem;
      @include media-breakpoint-down(sm) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
      &-col {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
      &-col:not(:last-child) {
        margin-right: 1.4rem;
        @include media-breakpoint-down(sm) {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
      &-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-width: 16.6rem;
      }
      &-ttl {
        color: #787c82;
      }
      &-val {
        color: #000;
        margin-left: 0.3rem;
      }
    }
  }
  &__modals {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @include media-breakpoint-down(sm) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    & .e-btn {
      min-width: 16.4rem;
      padding: 0.6rem 1.5rem;
      white-space: nowrap;
      font-weight: 400;
      text-decoration: none;
      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }
    }
    & .e-btn:not(:last-child) {
      margin-right: 1rem;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }
    & .e-btn--plus {
      padding: 0.6rem 1.4rem 0.6rem 3.2rem;
      min-width: auto;
      max-width: 100%;
    }
    & .e-btn--outline {
      margin-left: auto;
      max-width: 100%;
      min-width: auto;
      width: auto;
    }
    & .e-btn--outline:active {
      color: #ffffff;
      background-color: #3e4b5d;
    }
  }
  &__table {
    &-sort {
      margin-top: 1.4rem;
    }
    &-body {
      background: #eff2f5;
      margin-top: 1.4rem;
      //@include media-breakpoint-down(lg){
      //    overflow: hidden;
      //    overflow-x: auto;
      //}
      & table {
        width: 100%;
        & thead {
          & th {
            color: #787c82;
            padding: 0.4rem 1rem;
            height: 5rem;
            font-weight: 400;
            white-space: nowrap;
            & button {
              color: #787c82;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              & .icon {
                color: #787c82;
                font-size: 0.5rem;
                margin-left: 1.5rem;
              }
            }
          }
          & th:not(:last-child) {
            border-right: 1px solid #d9dee2;
          }
        }
        & tbody {
          & td {
            white-space: nowrap;
            color: #15171a;
            padding: 0.4rem 1rem;
            font-weight: 400;
            height: 5rem;
          }
          & td:not(:last-child) {
            border-right: 1px solid #d9dee2;
          }
        }
        & tr {
          border-bottom: 1px solid #d9dee2;
        }
      }
      & .c-protocol__table-user {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        &-desc {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        &-name {
          color: #15171a;
          white-space: nowrap;
          text-overflow: ellipsis;
          min-width: 300px;
          max-width: 95%;
          overflow: hidden;
          margin-right: 1rem;
        }
        &-id {
          color: #787c82;
          font-size: 1rem;
        }
      }
      & .c-protocol__table-input .form-group {
        margin-bottom: 0;
      }
      & .c-protocol__table-input .e-input {
        border: none;
        width: 100%;
        min-width: 100%;
        padding: 1rem 0;
        background: transparent;
        padding: 0;
      }
      & .user {
        min-width: 40rem;
      }
      & .age {
        min-width: 5.2rem;
      }
      & .team {
        max-width: 19.1rem;
        overflow-x: auto;
        text-overflow: ellipsis;
        overflow-wrap: anywhere;
        white-space: normal;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      & .result {
        min-width: 9rem;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
      & .change {
        min-width: 9rem;
      }
      & .place {
        min-width: 12.2rem;
      }
      & .name {
        min-width: 48rem;
        & .c-protocol__table-input {
          width: 100%;
          margin-right: 1rem;
        }
      }
      & .e-time-filter-list__item {
        max-height: 20rem;
        overflow: hidden;
        overflow-y: auto;
        @include media-breakpoint-down(sm) {
          max-height: 10rem;
        }
      }
      & .role {
        min-width: 49rem;
        position: relative;
        @include media-breakpoint-down(sm) {
          min-width: 40rem;
        }
        & .c-protocol__table-input {
          width: 100%;
          margin-right: 1rem;
        }
        & .c-protocol__table-input:hover > .e-time-filter__options {
          opacity: 1;
          visibility: visible;
          top: 70%;
          left: 1rem;
        }
      }
      & .e-time-filter__btn {
        font-weight: 400;
        width: 100%;
        text-align: left;
        white-space: nowrap;
      }
      & .e-time-filter__btn-default {
        color: #b0bac3;
      }
      & .e-time-filter__btn.active {
        color: #3e4b5d;
      }
      & .e-time-filter__options {
        left: 80%;
        top: -2rem;
        @include media-breakpoint-down(sm) {
          left: auto;
          right: 0;
          top: 100%;
        }
      }
    }
  }
  & .c-empty-block {
    margin-top: 1.4rem;
  }
}
