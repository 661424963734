.e-attach-document {
    display: flex;
    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      left: -999px;
    }
    label {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      font-size: 1.2rem;
      color: $oxford-blue;
      cursor: pointer;
      &:after {
        margin-right: 0.7rem;
        margin-top: 0.2rem;
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDUuMDQxNjdDMTEgNS4yMDgzMyAxMS4wNjI1IDUuMzU0MTcgMTEuMTg3NSA1LjQ3OTE3QzExLjMxMjUgNS42MDQxNyAxMS40NTgzIDUuNjY2NjcgMTEuNjI1IDUuNjY2NjdDMTEuNzkxNyA1LjY2NjY3IDExLjkzNzUgNS42MDQxNyAxMi4wNjI1IDUuNDc5MTdDMTIuNjY2NyA0Ljg3NSAxMyA0LjA2MjUgMTMgMy4yMDgzM0MxMyAyLjM1NDE3IDEyLjY2NjcgMS41NDE2NyAxMi4wNjI1IDAuOTM3NUMxMS40NTgzIDAuMzMzMzMzIDEwLjY0NTggMCA5Ljc5MTY3IDBDOC45Mzc1IDAgOC4xMjUgMC4zMzMzMzMgNy41MjA4MyAwLjkzNzVMMC45Mzc1IDcuNTIwODNDMC4zMzMzMzMgOC4xMjUgMCA4LjkzNzUgMCA5Ljc5MTY3QzAgMTAuNjQ1OCAwLjMzMzMzMyAxMS40NTgzIDAuOTM3NSAxMi4wNjI1QzEuNTQxNjcgMTIuNjY2NyAyLjM1NDE3IDEzIDMuMjA4MzMgMTNDNC4wNjI1IDEzIDQuODc1IDEyLjY2NjcgNS40NzkxNyAxMi4wNjI1TDkuOTM3NSA3LjYwNDE3TDkuOTU4MzMgNy41ODMzM0MxMC42MjUgNi44MzMzMyAxMC41ODMzIDUuNjg3NSA5Ljg3NSA0Ljk1ODMzQzkuNTIwODMgNC42MDQxNyA5LjA0MTY3IDQuMzk1ODMgOC41MjA4MyA0LjM5NTgzQzggNC4zOTU4MyA3LjUyMDgzIDQuNjA0MTcgNy4xNjY2NyA0Ljk1ODMzTDMuNSA4LjYyNUMzLjM3NSA4Ljc1IDMuMzEyNSA4Ljg5NTgzIDMuMzEyNSA5LjA2MjVDMy4zMTI1IDkuMjI5MTcgMy4zNzUgOS4zNzUgMy41IDkuNUMzLjcyOTE3IDkuNzI5MTcgNC4xNDU4MyA5LjcyOTE3IDQuMzc1IDkuNUw4LjA0MTY3IDUuODMzMzNDOC4yOTE2NyA1LjU4MzMzIDguNzI5MTcgNS41ODMzMyA5IDUuODMzMzNDOS4yNzA4MyA2LjEwNDE3IDkuMjcwODMgNi41MjA4MyA5IDYuNzkxNjdMNC42MDQxNyAxMS4xODc1QzQuMjI5MTcgMTEuNTYyNSAzLjcyOTE3IDExLjc3MDggMy4yMDgzMyAxMS43NzA4QzIuNjg3NSAxMS43NzA4IDIuMTg3NSAxMS41NjI1IDEuODEyNSAxMS4xODc1QzEuNDM3NSAxMC44MTI1IDEuMjI5MTcgMTAuMzEyNSAxLjIyOTE3IDkuNzkxNjdDMS4yMjkxNyA5LjI3MDgzIDEuNDM3NSA4Ljc3MDgzIDEuODEyNSA4LjM5NTgzTDguMzk1ODMgMS44MTI1QzguNzcwODMgMS40Mzc1IDkuMjcwODMgMS4yMjkxNyA5Ljc5MTY3IDEuMjI5MTdDMTAuMzEyNSAxLjIyOTE3IDEwLjgxMjUgMS40Mzc1IDExLjE4NzUgMS44MTI1QzExLjk1ODMgMi41ODMzMyAxMS45NTgzIDMuODMzMzMgMTEuMTg3NSA0LjYwNDE3QzExLjA2MjUgNC43MDgzMyAxMSA0Ljg3NSAxMSA1LjA0MTY3WiIgZmlsbD0iIzNFNEI1RCIvPgo8L3N2Zz4K);
      }
    }
  }