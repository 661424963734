.c-password-recovery {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__form {
        padding-top: 7.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .e-btn {
            margin-top: 1rem;
        }
    }
    &__ico {
        display: flex;
        margin-top: 4.7rem;
        .icon-mail {
            font-size: 4.2rem;
        }
    }
    &__info {
        margin-top: 2.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 37rem;
        text-align: center;
    }
}
