.c-global-search{
    &__wrapper {
        margin-top: 2rem; 
    }
    & .global-search {
        margin-top: 2rem;
        @include media-breakpoint-down(sm) {
            margin: 1rem 0 0 0;
        }
        & input {
            border: 2px solid #EFF2F5; }
    }
    &__btn-menu.e-btn{
        margin-top: 1rem;
        width: 100%;
        display: none;
        @include media-breakpoint-down(sm) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }
    }
}

.global-search{
    position: relative;
    & .icon-search {
        position: absolute;
        left: 2rem;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        color: #000; }
    & .e-input--remove {
        position: absolute;
        right: 2rem;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        color: #000; }
    & input {
        padding: 0.85rem 4.5rem; }
}