.e-title {
   &--lg {
     font-size: 2.4rem;
   }
   &--md {
     font-size: 1.8rem;
   }
   &--sm {
     font-size: 1.4rem;
   }
   &--tac {
     text-align: center;
   }
}