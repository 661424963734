.c-opportunity {
    &__wrapper {
        padding-top: 1.5rem; 
    }
    &__nav {
        margin-bottom: 2rem; 
    }
    &-welcome {
        border: 2px solid #D9DEE2;
        padding: 3.5rem;
        position: relative; 
    }
    &-welcome__stars {
        margin: 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 1.5rem; 
    }
    &-welcome__stars .icon-star {
        font-size: 1.6rem; 
    }
    &-welcome__stars .icon-star:not(:last-child) {
        margin-right: 3rem; 
    }
    &-welcome__info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column; 
    }
    &-welcome__txt {
        color: #15171A;
        text-align: center; 
    }
    &-welcome__close {
        position: absolute;
        right: 2rem;
        top: 2rem; 
    }
    &-welcome__close .icon-border-close {
        font-size: 2.4rem; 
    }
    &__list {
        margin-top: 2rem; 
    }
    &-item:not(:last-child) {
        margin-bottom: 2rem; 
    }
    &-item__head {
        background-color: #EFF2F5;
        margin-bottom: 1px;
        padding: 2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; 
    }
    &-item__info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; 
    }
    &-item__img {
        height: 6rem;
        width: 6rem;
        border-radius: 50%;
        overflow: hidden; 
    }
    &-item__img img {
        height: 100%; 
    }
    &-item__titles {
        margin-left: 1rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column; 
    }
    &-item__title {
        font-weight: bold;
        color: #3E4B5D;
        line-height: normal;
        margin-bottom: 0.3rem;
        font-size: 1.4rem; 
    }
    &-item__date {
        color: #787C82;
        margin-top: 0.3rem; 
    }
    &-item__subtitle {
        color: #787C82; 
    }
    &-item__body {
        background-color: #EFF2F5;
        padding: 1.3rem 1.4rem 1.3rem 2rem; 
    }
    &-item__desc {
        max-height: 6.6rem;
        overflow: hidden; 
    }
    &-item__desc.all-text {
        max-height: 100%; 
    }
    &-item__show-more {
        color: #B0BAC4;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; 
    }
    &-item__show-more:hover {
        color: #3E4B5D;
    }
    &-item__show-more:hover .icon {
        color: #3E4B5D; 
    }
    &-item__show-more .icon {
        font-size: 0.6rem;
        color: #B0BAC4;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; 
    }
    &-item__show-more.active .icon {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); 
    }
    &-item__action{
        text-align: right;
    }
    &-item__type{
        color: #FFFFFF;
        background-color: #B0BAC3;
        border-radius: 5px;
        float: right;
        text-transform: uppercase;
        padding: 0px 5px;
        margin-top: -15px;
    }
    &_filter {
        margin-top: 3rem; 
    }
      
      
      
    
}