.chat-item {
    position: relative;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eff2f5;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    transition: all 0.3s ease;
    &__img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
        flex-shrink: 0;
        @include media-breakpoint-down(sm) {
            width: 30px;
            height: 30px;
            margin-right: 12px;
        }
    }
    &__info {
        width: 372px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @include media-breakpoint-down(sm) {
            overflow: hidden;
        }
        &-author {
            margin: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color: #3e4b5d;
        }
        &-text {
            width: 100%;
            margin: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #15171a;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    &__time {
        width: 100px;
        text-align: right;
        display: flex;
        flex-direction: column;
    }
    &__time-value {
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #787c82;
    }
    &__wrapper {
        position: relative;
    }
    &__visible {
        display: none;
    }
    &:hover {
        background-color: #E3E8ED;
        text-decoration: none;
        transition: all 0.3s ease;
    }
    &_name-block {
        cursor: default;
        margin-top: 17px;
        margin-bottom: 0;
        &:hover {
            background-color: #eff2f5;
            text-decoration: none;
            transition: all 0.3s ease;
        }
    }
}

.chat-content {
    margin-top: 2rem;
    background-color: #eff2f5;
    padding: 2rem;
    position: relative;
    height: calc((var(--vh, 1vh) * 100) - 250px);
    @include media-breakpoint-down(sm) {
        margin-top: 0.5rem;
        height: calc((var(--vh, 1vh) * 100) - 200px);
    }
    &__list {
        overflow: hidden;
        overflow-y: auto;
        max-height: 100%;
        padding-bottom: 6rem;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__group {
        &-head {
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 1.5rem;
            &-data {
                color: #787c82;
                font-size: 12px;
                padding: 4px 11.5px;
                background: #e3e8ed;
                border-radius: 18px;
            }
        }
        &-body {
        }
    }
    &__message {
        display: flex;
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
        &-image {
            height: 4rem;
            width: 4rem;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 1rem;
            flex-shrink: 0;
            object-fit: cover;
        }
        &-body_head-author {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color: #15171a;
            margin-right: 0.5rem;
            @include media-breakpoint-down(sm) {
                line-height: 18px;
                font-size: 12px;
            }
        }
        &-body_head-time {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #787c82;
            @include media-breakpoint-down(sm) {
                line-height: 18px;
                font-size: 12px;
            }
        }
        &-body_text {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #15171a;
            @include media-breakpoint-down(sm) {
                line-height: 18px;
                font-size: 12px;
            }
        }
    }
    &__answer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        border-top: 1px solid #d9dee2;
        min-height: 6rem;
        background-color: #eff2f5;
        z-index: 99;
        padding: 0.5rem 2rem;
    }
    &__answer-field {
        width: 100%;
        color: #3e4b5d;
        background: transparent;
        border: none;
        padding: 0;
        padding-right: 2rem;
        resize: none;
        margin: 0;
        -ms-flex-item-align: center;
        align-self: center;
        word-break: break-word;
        height: 2.5rem;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        @include media-breakpoint-down(sm) {
            line-height: 18px;
            font-size: 12px;
        }
    }
    &__answer-button {
        cursor: pointer;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
    }
    &__answer-sub {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 18px;
        text-align: center;
        color: #15171a;
    }
}

.left-side {
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}