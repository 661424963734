.c-modal-inst-remove {
    overflow-y: scroll;
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    & .modal__title {
        letter-spacing: -0.1px;
    }
    &__wrapper {
        padding: 5.5rem 16rem 9.2rem;
        @include media-breakpoint-down(sm){
            padding: 4rem;
        }
        @include media-breakpoint-down(xs){
            padding: 2rem;
        }
    }
    &__form {
        margin-top: 3.5rem;
    }
    &__btn {
        margin-top: 4rem; 
    }
}