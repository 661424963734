.c-modal-admin {
    &::after {
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      opacity: 0.8;
      background: #3e4b5d;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    &__wrapper {
      padding: 5.5rem 16rem 7rem;
      @include media-breakpoint-down(sm) {
        padding: 2rem;
      }
    }
    .c-multiselect {
      margin-bottom: 3rem;
    }
  }