.hidden-menu {
  display: none;
}
.mobile-burger--diplom {
  display: none;
}
.c-diplom-page {
  padding-top: 2rem;
  &__inner {
    padding-top: 2rem;
  }
  &-banner {
    display: flex;
    align-items: center;
    background-color: $athens-gray;
    padding: 2rem 2rem 2rem 1.5rem;
    position: relative;
    margin-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    &__change-img {
      height: 8rem;
      width: 8rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        height: 6rem;
        width: 6rem;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
    &__empty-img {
      height: 8rem;
      width: 8rem;
      background-image: url(./../../../images/diplom.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      @include media-breakpoint-down(sm) {
        height: 6rem;
        width: 6rem;
      }
    }
    &__desc {
      display: flex;
      flex-direction: column;
      margin-left: 1.5rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-top: 1.5rem;
      }
    }
    &__name {
      font-size: 1.6rem;
      font-weight: bold;
    }
    &__date {
      color: $rolling-stone;
      margin-top: 5px;
      &-txt {
        color: #15171a;
      }
    }

    .c-edit__wrapper {
      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2rem;
      }
    }
  }
  &__all-back {
    color: #b0bac4;
    font-size: 1.4rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;
      color: #b0bac4;
    }
    span {
      margin-left: 1rem;
    }
  }
  .c-user-item__name {
    color: #15171a;
  }
  .c-user-item__info {
    margin-left: 0;
  }
  .c-user-item {
    padding-top: 1.4rem;
  }

  &-edit {
    background: #eff2f5;
    padding: 4rem 0 8rem 0;
    &__place {
      position: relative;
      img {
        width: 100%;
        @include media-breakpoint-down(xs) {
          min-height: 60rem;
        }
      }
    }
    &__actions {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;

      &.production {
        input,
        textarea,
        .text-input-line,
        div {
          border: 0 !important;
          pointer-events: none !important;
          resize: none !important;
          transition: 0 !important;
          &::placeholder {
            transition: 0 !important;
            opacity: 0 !important;
          }
        }
      }
    }
    &__action {
      &.download {
        input,
        textarea {
          border: none !important;
        }
        resize: none !important;
      }
      position: relative;
      &.input {
        height: 50px;
        @include media-breakpoint-down(sm) {
          height: 30px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &.selected-input {
        input,
        textarea {
          border: 1px dashed #0066ff;
        }
      }
      input,
      textarea {
        background-color: transparent;
        border: 1px dashed #787c82;
        width: 100%;
        height: 100%;
      }
      input {
        text-align: center;
        color: #15171a;

        &::placeholder {
          font-size: 20px;
          color: #787c82;
          transition: all 0.3s ease;
          letter-spacing: normal;
          @include media-breakpoint-down(md) {
            font-size: 1.6rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 1.4rem;
          }
        }
        &:focus {
          &::placeholder {
            opacity: 0;
          }
        }
      }
      &.textarea {
        height: 27rem;
        @include media-breakpoint-down(lg) {
          height: 25rem;
        }
        @include media-breakpoint-down(md) {
          height: 18rem;
        }
        @include media-breakpoint-down(sm) {
          height: 12rem;
        }
        textarea {
          padding: 1.5rem;
          font-size: 16px;
          font-weight: 400;
          resize: none;
          text-align: center;
          overflow: hidden;
          &::placeholder {
            font-weight: 400;
          }
        }
      }
      &.input {
        height: 5rem;
        @include media-breakpoint-down(md) {
          height: 3rem;
        }
        &.bold * {
          font-size: 3rem !important;
          @include media-breakpoint-down(md) {
            font-size: 2rem !important;
          }
          @include media-breakpoint-down(sm) {
            font-size: 1.4rem !important;
          }
        }
        input::placeholder {
          font-weight: 400;
        }
      }
      &.tac textarea {
        text-align: center !important;
        padding: 1.5rem 15rem;
        @include media-breakpoint-down(md) {
          padding: 0.5rem 6rem;
        }
        @include media-breakpoint-down(sm) {
          padding: 0.5rem 2rem;
          font-size: 14px;
        }
      }
      &.bold * {
        font-weight: 600 !important;
        font-size: 2rem !important;
        @include media-breakpoint-down(md) {
          font-size: 1.6rem !important;
        }
        @include media-breakpoint-down(sm) {
          font-size: 1.4rem !important;
        }
      }
      &.small * {
        font-size: 16px !important;
        font-weight: 400 !important;
        @include media-breakpoint-down(sm) {
          font-size: 1.4rem !important;
        }
      }
    }
  }
}

.c-diplom {
  &-actions {
    display: flex;
    margin: 2.2rem 0;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    &__text {
      display: flex;
      border: 1px solid #b0bac4;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    .c-select__trigger {
      border: none;
    }
    .c-select {
      margin-bottom: 0;
      min-width: auto;
      width: 100%;
    }
    .c-select__arrow {
      display: none;
    }
    .c-select__options {
      margin: 0 -1px;
      border: 1px solid #b0bac4;
    }
    .c-select__option:not(:last-child) {
      border-bottom: 1px solid #b0bac4;
    }
    .c-select__trigger {
      color: #3e4b5d;
      padding: 0.5rem 1rem;

      &.center {
        justify-content: center;
      }
    }
    &-option {
      width: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      &:not(:last-child) {
        border-right: 1px solid #b0bac4;
      }
      &.ff {
        min-width: auto;
        width: 17rem;
        @include media-breakpoint-down(sm) {
          width: auto;
          flex-grow: 1;
        }
      }
      &.fz {
        min-width: auto;
      }
      &.bold .c-diplom-actions-option__btn {
        font-weight: bold;
      }
      &.cursive .c-diplom-actions-option__btn {
        font-style: italic;
      }
      &.underline .c-diplom-actions-option__btn {
        text-decoration: underline;
      }
      &__btn {
        color: #3e4b5d;
        text-transform: capitalize;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        background-color: transparent;
        border: 0;
      }
      &__btn .icon {
        width: 16px;
        height: 11px;
        color: #3e4b5d;
      }
      &.active * {
        color: #2f81f3;
      }
    }
    &__btns {
      display: flex;
      margin-left: auto;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-top: 1rem;
      }
      .e-btn {
        min-width: 12rem;
        width: auto;
        font-size: 1.4rem;
        padding: 0.6rem 1rem;
        font-weight: 400;
        img {
          max-height: 30px;
          margin: -15px;
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    &__bg {
      margin-left: 1.7rem;
      border: 1px solid #b0bac4;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 6rem;
      @include media-breakpoint-down(sm) {
        margin-top: 1rem;
        margin-left: 0;
        min-height: 3.3rem;
        width: 100%;
      }
    }
  }
}

.text-input-line {
  position: absolute;
  .dropper {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    // background-color: rgba(99, 148, 222, 0.65);
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    opacity: .6;
    transition: all .3s ease;
    img {
      width: 14px;
      height: 14px;
      pointer-events: none;
    }
  }

  &:hover {
    .dropper {
      opacity: 1;
    transition: all .3s ease;
    }
  }
}

.link-requirements {
  color: $dodger-blue;
}