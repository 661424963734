.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

.icon-inactive {
  color: #B0BAC4;
  opacity: .7;
}
  
  /* ==========================================
  Single-colored icons can be modified like so:
  .icon-name {
    font-size: 32px;
    color: red;
  }
  ========================================== */
  .icon-calendar {
    width: 0.875em;
  }
  
  .icon-close-eye {
    width: 1.0771484375em;
    fill: none;
  }
  
  .icon-down-arrow {
    width: 2em;
    fill: none;
  }
  
  .icon-logo {
    width: 4.388671875em;
  }
  
  .icon-mail {
    width: 1.54761904762em;
    fill: none;
  }
  
  .icon-notify {
    width: 0.75em;
  }
  
  .icon-open-eye {
    width: 1.0771484375em;
    fill: none;
  }
  
  .icon-success {
    width: 1.111328125em;
  }
  .icon-close {
    width: 1em;
  }

  .icon-alert {
    width: 0.75em;
  }
  
  .icon-event {
    width: 0.875em;
  }
  
  .icon-girl {
    width: 0.875em;
  }
  .icon-star {
    width: 1.06640625em;
  }
  .icon-photo {
    width: 1.2em;
  }
  .icon-diplom {
    width: 1.5em;
    height: 2em;
  }