.c-modal-welcome {
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__wrapper {
        padding: 11rem 0;
        margin: 0 auto;
        max-width: 39rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
