
.c-my-page {
    &__wrapper {
      margin-top: 2rem;
    }
    &-user {
      display: flex;
      align-items: center;
      background-color: $athens-gray;
      padding: 1.5rem 2rem 1.5rem 1.5rem;
      position: relative;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
      }
      &__change-img {
        height: 9rem;
        width: 9rem;
        border: 2px solid $oxford-blue;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        @include media-breakpoint-down(sm) {
          height: 7rem;
          width: 7rem;
        }
        .icon-photo {
          font-size: 2.5rem;
        }
      }
      &__img {
        max-width: 8rem !important;
        max-height: 8rem !important;
        border-radius: 50%;
        @include media-breakpoint-down(sm) {
          max-height: 8rem !important;
          max-width: 8rem !important;
          border-radius: 50%;
        }
      }
      &__empty-img {
        height: 8.7rem;
        width: 8.7rem;
        border: 2px solid $white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(sm) {
          height: 6rem;
          width: 6rem;
        }
      }
      &__desc {
        display: flex;
        flex-direction: column;
        margin-left: 1.5rem;
        @include media-breakpoint-down(sm) {
          margin-left: 0;
          margin-top: 1.5rem;
        }
      }
      &__title {
        font-size: 1.6rem;
        font-weight: bold;
      }
      &__city {
        color: $rolling-stone;
      }
      &__options {
        margin-left: auto;
        position: relative;
        @include media-breakpoint-down(sm) {
          position: absolute;
          top: 2rem;
          right: 2rem;
        }
        .icon-border-down {
          font-size: 2.4rem;
        }
        &:hover {
          .c-my-page-user__options-list {
            opacity: 1;
            visibility: visible;
          }
        }
        &-list {
          position: absolute;
          right: 0;
          padding-top: 0.8rem;
          z-index: 999;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s ease;
        }
        &-items {
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
          background-color: $white;
          display: flex;
          flex-direction: column;
          padding: 2rem;
        }
        &-item {
          color: #1F2125;
          white-space: nowrap;
          text-align: left;
          transition: all 0.3s ease;
          &:hover {
            color: $oxford-blue;
          }
          &:not(:last-child) {
            padding-bottom: 0.8rem;
          }
        }
      }
    }
    &-hint {
      background-color: $athens-gray;
      padding: 2rem;
      margin-top: 2rem;
      &__head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.8rem;
      }
      &__title {
        font-size: 1.4rem;
        line-height: 1.9rem;
        margin-bottom: 0;
      }
      &__remove {
        .icon-border-close {
          font-size: 2.4rem;
        }
      }
    }
    &-info {
      margin-top: 2.9rem;
      &__head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.1rem;
      }
      &__edit {
        color: #C6CED6;
        font-weight: bold;
      }
      &-item {
        background-color: $athens-gray;
        padding: 2.9rem 2rem;
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
          margin-bottom: 1rem;
          @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
          }
        }
        &__information {
          color: $oxford-blue;
          font-weight: bold;
        }
        &__specialize {
          color: $rolling-stone;
        }
      }
      &-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $athens-gray;
        padding: 3.5rem;
        &__txt {
          margin-top: 1.5rem;
        }
      }
    }
  }