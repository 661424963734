.c-edit{
    &__wrapper {
        text-align: right;
        margin-left: auto;
        position: relative;
        @include media-breakpoint-down(sm) {
            display: flex;
            margin-left: 0;
            align-items: center;
            justify-content: space-between;
        }
    }
    &__wrapper:hover .c-event-item-invitations-options {
        opacity: 1;
        visibility: visible;
    }
    &.no-ml {
        margin-left: inherit;
    }
    & .icon-border-down {
        font-size: 2.4rem;    
    }
    &:hover .c-edit__options {
        opacity: 1;
        visibility: visible;                
    }
    &__options {    
        position: absolute;
        right: 0;
        padding-top: 0.8rem;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease;
        min-width: 22rem;        
    }
    &__items {
        -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        background-color: white;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 2rem;
    }
    &__item {
        cursor: pointer;
        color: #1F2125;
        white-space: nowrap;
        text-align: left;
        &:not(:last-child) {
            padding-bottom: 0.8rem;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

.c-edit__temp {
    &:hover .c-edit__options {
        opacity: 1;
        visibility: visible;                
    }
}