.c-modal-report {
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__wrapper {
      padding: 5.5rem 16rem 9rem;
      //@include media-breakpoint-down(lg) {
       // padding: 4rem;
      //}
      @include media-breakpoint-down(xs) {
        padding: 2rem;
      }
      &-message {
        padding-right: 9rem;
        padding-left: 9rem;
        padding-bottom: 6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
      }
    }
    &__form {
      margin-top: 3rem;
      .e-textarea {
        min-height: 18rem;
      }
    }
    &__btn {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    &__btn-message {
      margin-top: 27px;
    }
  }