.e-attach-admin-document {
    display: flex;
    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      left: -999px;
    }
    label {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      font-size: 1.4rem;
      color: $oxford-blue;
      cursor: pointer;
      &:after {
        margin-right: 0.7rem;
        margin-top: 0.2rem;
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjUgMEM1LjIyMzg2IDAgNSAwLjIyMzg1OCA1IDAuNVY1SDAuNUMwLjIyMzg1OCA1IDAgNS4yMjM4NiAwIDUuNUMwIDUuNzc2MTQgMC4yMjM4NTggNiAwLjUgNkg1TDUgMTAuNUM1IDEwLjc3NjEgNS4yMjM4NiAxMSA1LjUgMTFDNS43NzYxNCAxMSA2IDEwLjc3NjEgNiAxMC41TDYgNkwxMC41IDZDMTAuNzc2MSA2IDExIDUuNzc2MTQgMTEgNS41QzExIDUuMjIzODYgMTAuNzc2MSA1IDEwLjUgNUw2IDVWMC41QzYgMC4yMjM4NTggNS43NzYxNCAwIDUuNSAwWiIgZmlsbD0iIzNFNEI1RCIvPgo8L3N2Zz4K);
      }
    }
  }