.e-error-notification{
    &-wrapper{
        display: flex;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 15px 20px;
        box-shadow: 0px 2px 10px rgba(62, 75, 93, 0.5);
        min-width: 420px
    }
    &-left{
        flex: 0.9
    }
    &-right{
        flex: 0.1
    }
    &-error{
        color: #F10F2A;
        font-size: 14px;
        font-weight: bold;
    }
    &-success{
        color: #2F81F3;
        font-size: 14px;
        font-weight: bold;
    }
}

.notification-container--top-center{
    max-width: 420px !important;
    left: calc(50% - 220px) !important;
}