
.react-datepicker {
    font-size: 1.3rem !important;
    &__current-month {
        font-size: 1.5rem !important;
    }
    &__header {
        padding-top: 6px !important;
    }
    &__navigation {
        top: 13px !important;
    }
    &__day-name, .react-datepicker__day {
        margin: 0.5rem !important;
    }
}

.react-datepicker-wrapper{
    width: 100%;
}

#birthDay::placeholder { color: #15171A; }