.c-event {
  &-page {
    &__wrapper {
      margin-top: 2rem;
    }

    &-user {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: #eff2f5;
      padding: 2rem 2rem 2rem 1.5rem;
      position: relative;
      margin-bottom: 2rem;
      @include media-breakpoint-down(sm) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
      }
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 3rem 0 1.5rem 0;

      .c-event-page__title {
        margin: 0;
      }

      .c-event-page__link {
        color: #b0bac4;
        font-size: 1.4rem;
      }
    }

    &-user__change-img {
      height: 9rem;
      width: 9rem;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      position: relative;
      //border: 2px solid #3E4B5D;
      background-color: #d9dee2;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        height: 7rem;
        width: 7rem;
      }

      & img {
        border-radius: 50%;
        height: 8rem;
        width: 8rem;
        @include media-breakpoint-down(sm) {
          height: 6rem;
          width: 6rem;
        }
      }
    }

    &-user__empty-img {
      height: 8rem;
      width: 8rem;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        height: 6rem;
        width: 6rem;
      }
    }

    &-user__desc {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-left: 1.5rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-top: 1.5rem;
      }
    }

    &-user__name {
      font-size: 1.6rem;
      font-weight: bold;
    }

    &-user__section {
      color: #787c82;
    }

    &-user__nomination {
      position: absolute;
      top: 68%;
      left: 68%;
      cursor: pointer;
    }

    &-user__nomination:hover .c-community-page-user__nomination-wrap {
      opacity: 1;
      visibility: visible;
    }

    &-user__nomination .icon {
      font-size: 3.3rem;
    }

    &-user__nomination-wrap {
      background-color: #fff;
      position: relative;
      padding: 1rem;
      font-size: 1.2rem;
      width: 26rem;
      z-index: 6;
      -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      margin-top: 1rem;
      margin-left: -2.3rem;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    &user__nomination-wrap:after {
      content: "";
      top: -1rem;
      left: 3rem;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #ffffff transparent;
      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 2rem;
      }
    }

    &__nav {
      margin-bottom: 2rem;

      &.e-nav-line .tab-content {
        margin-top: 0.1rem;
      }

      &.e-nav-line .tab-content.mt20 {
        margin-top: 2rem;
      }

      &.e-nav-line .tab-pane {
        background-color: $athens-gray;
        padding: 1.7rem 2rem;
        min-height: 9rem;
      }

      &.e-nav-line .tab-pane.bg-white {
        background-color: $white;
      }

      &.e-nav-line .tab-pane.no-padding {
        padding: 0;
      }

      &--diplom {
        .e-btn.e-btn--plus {
          margin-left: auto;
          display: block;
          margin-right: 0;
          max-width: 100%;
          padding-right: 2rem;
        }
      }
    }

    .c-event-page__nav.e-nav-line .tab-content {
      margin-top: 0.1rem;
    }
    .c-event-page__nav.e-nav-line .tab-content.mt20 {
      margin-top: 2rem;
    }

    .c-event-page__nav.e-nav-line .tab-pane {
      background-color: #eff2f5;
      padding: 1.7rem 2rem;
      min-height: 9rem;
    }

    .c-event-page__nav.e-nav-line .tab-pane.bg-white {
      background-color: white;
    }

    .c-event-page__nav.e-nav-line .tab-pane.no-padding {
      padding: 0;
    }

    &__tab-info {
      max-height: 8rem;
      @include media-breakpoint-down(sm) {
        max-height: none;
      }
    }

    &__tab-address {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding-bottom: 4rem;
      @include media-breakpoint-down(sm) {
        justify-content: flex-start;
      }
    }

    &__tab-address .icon {
      fill: transparent;
      stroke-width: 1;
      margin-right: 1.3rem;
      @include media-breakpoint-down(sm) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      //@include media-breakpoint-down(sm) {
      //width: 100%;
      //margin-bottom: 1rem;
      //margin-right: 0;
      //max-width: 100%;
      //-webkit-box-pack: start;
      //-ms-flex-pack: start;
      //justify-content: flex-start;
      //}
    }

    &__tab-address-txt {
      @include media-breakpoint-down(sm) {
        max-width: 195px;
      }
    }
    &-news {
      //margin-top: 2.5rem;
    }

    &-news__title {
      font-size: 1.4rem;
    }

    &-news__list {
      margin-top: 1.6rem;
    }

    &__btn.e-btn {
      margin-bottom: 1rem;
      display: none;
      height: 5rem;
      @include media-breakpoint-down(sm) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
    }

    &__btn-menu.e-btn {
      margin-bottom: 2rem;
      display: none;
      width: 100%;
      @include media-breakpoint-down(sm) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
    }

    &__title {
      font-size: 1.4rem;
      margin: 3rem 0 1.5rem 0;
    }

    &-result {
      background-color: #eff2f5;
      min-height: 5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 1rem 2rem;
      margin-bottom: 2rem;
    }

    &-result:hover {
      text-decoration: none;
    }

    &-result:hover .icon-down-arrow {
      color: #3e4b5d;
    }
    &-result__header {
      font-weight: 800;
      color: #3e4b5d;
    }

    &-result__text {
      font-weight: 600;
      color: #3e4b5d;
    }

    &-result__text-red {
      font-weight: 600;
      color: #f10f2a;
    }

    &-result__text-reason {
      font-weight: 600;
      color: #3e4b5d;
      margin-right: -30rem;
      cursor: pointer;
      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }

      &-admin {
        font-weight: 600;
        color: #3e4b5d;
        margin-right: -10rem;
        cursor: pointer;
      }
    }

    &-result .icon-down-arrow {
      font-size: 0.8rem;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      color: #b0bac4;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    &__diploms {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
      // display: flex;
      // flex-wrap: wrap;
      // margin: 0 -1rem;
      @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
      }
      @include media-breakpoint-down(xs) {
        margin: 1rem 0;
      }
    }

    &-diplom {
      width: calc(33.333% - 2rem);
      display: flex;
      align-items: center;
      padding: 1rem 1rem 1rem 1.5rem;
      background: #eff2f5;
      // margin: 0 0 1rem;
      @include media-breakpoint-down(sm) {
        width: calc(50% - 2rem);
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
        margin: 0;
        // margin-bottom: 2rem;
      }

      &__img {
        height: 14.4rem;
        width: 10rem;
      }
    }
    &__head {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-align-items: center;
      align-items: center;
      margin: 3rem 0 1.5rem;
    }
    &__title {
      margin: 0;
    }
    &__link {
      color: #b0bac4;
      font-size: 1.4rem;
      &:hover {
        color: #b0bac4;
        text-decoration: underline;
      }
    }
    .e-btn.e-btn--plus {
      margin-left: auto;
      display: block;
      margin-right: 0;
      max-width: 100%;
      padding-right: 2rem;
    }
  }

  &-sidebar {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    &__head {
      display: none;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    &.show {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      z-index: 50;
      background-color: white;
    }

    &__block-gray {
      background-color: #eff2f5;
      padding: 1.5rem 2rem;
      margin-bottom: 2rem;
    }

    &__content {
      @include media-breakpoint-down(sm) {
        padding: 2rem;
      }
    }

    &__btn.e-btn--outline {
      margin-bottom: 2rem;
      min-height: 5rem;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__title {
      font-weight: bold;
    }

    &-members {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    &-organizer__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-top: 2rem;
    }

    &-organizer-user {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      overflow-wrap: anywhere;
      -webkit-box-align: start;
      -ms-flex-align: start;
      // align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &__name {
        color: #3e4b5d;
      }

      &__img {
        height: 1.8rem;
        width: 1.8rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1rem;
        margin-top: 0.5rem;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        & img {
          height: 100%;
          width: 100%;
        }
      }
    }

    &-admins {
      &__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 2rem;
      }

      &-user {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        &.show {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          z-index: 50;
          background-color: white;
        }
        &__img {
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 1rem;
          min-width: 4rem;
        }
        &__name {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &-menu {
      &__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 2rem;
      }

      &-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        &__text {
          color: #3e4b5d;
        }

        & .icon {
          margin-right: 1.6rem;
          font-size: 1.3rem;
          fill: transparent;
          stroke-width: 1px;
        }
        &__title {
          font-weight: bold;
        }
        &-members {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        &-organizer__list {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          margin-top: 2rem;
        }
      }

      &-btn.e-btn.e-btn--filled {
        width: 100% !important;
        max-width: 100%;
        min-width: auto;
        font-size: 1.4rem;
        font-weight: 400;
        padding: 0.5rem 5rem;
        min-height: 3.3rem;
      }
    }

    &-childs {
      &__head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }

      &__list {
        padding: 2rem 0 1rem 0;
      }

      &__item {
        color: #787c82;
        display: block;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
      &-sidebar {
        @include media-breakpoint-down(sm) {
          display: none;
        }
        &__head {
          display: none;
          @include media-breakpoint-down(sm) {
            display: block;
          }
        }
        &.show {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          z-index: 50;
          background-color: white;
        }
        &__block-gray {
          background-color: #eff2f5;
          padding: 1.5rem 2rem;
          margin-bottom: 2rem;
        }
        &__content {
          @include media-breakpoint-down(sm) {
            padding: 2rem;
          }
        }
        &__btn.e-btn--outline {
          margin-bottom: 2rem;
          min-height: 5rem;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        &__title {
          font-weight: bold;
        }
        &-members {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        &-organizer__list {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          margin-top: 2rem;
        }
        &-organizer-user {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          overflow-wrap: anywhere;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          &__name {
            color: #3e4b5d;
          }
          &__img {
            height: 1.8rem;
            width: 1.8rem;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 1rem;
            margin-top: 0.5rem;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            & img {
              height: 100%;
              width: 100%;
            }
          }
        }
        &-admins {
          &__list {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin-top: 2rem;
          }
          &-user {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
            &__img {
              height: 4rem;
              width: 4rem;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 1rem;
              min-width: 4rem;
            }
            &__name {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        &-menu {
          &__list {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin-top: 2rem;
          }
          &-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            &:not(:last-child) {
              margin-bottom: 1.5rem;
            }
            &__text {
              color: #3e4b5d;
            }
            & .icon {
              margin-right: 1.6rem;
              font-size: 1.3rem;
              fill: transparent;
              stroke-width: 1px;
            }
            & .icon-use {
              stroke-width: 0;
            }
          }
        }
        &__wrapper {
          position: relative;
          &:hover .c-event-sidebar__child-options {
            opacity: 1;
            visibility: visible;
          }
        }
        &__child-options {
          background: #ffffff;
          -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
          z-index: 5;
          padding: 2rem;
          width: 22rem;
          position: absolute;
          left: -5rem;
          opacity: 0;
          visibility: hidden;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
          &-title {
            font-size: 1.4rem;
            font-size: bold;
          }
          &-list {
            margin-top: 2.1rem;
            margin-bottom: 2rem;
          }
          & .e-box {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            &:not(:last-child) {
              margin-bottom: 1.8rem;
            }
            & label {
              margin-left: 1rem;
              margin-bottom: 0;
            }
          }
          &-btn.e-btn.e-btn--filled {
            width: 100% !important;
            max-width: 100%;
            min-width: auto;
            font-size: 1.4rem;
            font-weight: 400;
            padding: 0.5rem 5rem;
            min-height: 3.3rem;
          }
        }
        &-childs {
          &__head {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
          }
          &__list {
            padding: 2rem 0 1rem 0;
          }
          &__item {
            color: #787c82;
            display: block;
            &:not(:last-child) {
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }
  }
}
