.c-news {
  &-item {
    margin-bottom: 1rem;
    &-title {
      background-color: $athens-gray;
      min-height: 6rem;
      padding: 1.5rem 2rem;
      margin-bottom: 1px;
      display: flex;
      align-items: center;
      &__img {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        overflow: hidden;
      }
      &__name {
        font-size: 1.4rem;
        color: $oxford-blue;
        font-weight: 600;
        margin-left: 1rem;
      }
    }
    &__wrapper {
      padding: 2rem;
      background-color: $athens-gray;
      position: relative;
    }
    &__head {
      display: flex;
      justify-content: space-between;
    }
    &__date {
      display: flex;
      align-items: center;
      line-height: normal;
      .icon {
        margin-right: 1.3rem;
      }
      .date {
        margin-right: 1rem;
      }
    }
    &__body {
      margin-top: 1.2rem;
    }
    &__desc {
      line-height: 2rem;
      // margin-bottom: 1rem;
    }
    &__desc label,
    label > a {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__images {
      // display: flex;
      // flex-wrap: wrap;
      // margin: 0 -0.5rem;
      // margin-bottom: 1.5rem;
      margin-top: 1rem;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      grid-template-areas:
        "photo4 photo4 photo4 photo4"
        "photo4 photo4 photo4 photo4"
        "photo4 photo4 photo4 photo4";
    }
    &__images-one {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas: 'photo1';
    }
    &__images-two {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: none;
    }
    &__images-three {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 
            'photo1 photo1'
            'photo2 photo22';
    }
    &__images-four {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: none;
    }
    &__images-five {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas: 
            'photo2 photo2 photo2 photo22 photo22 photo22'
            'photo3 photo3 photo33 photo33 photo333 photo333';
    }
    &__images-six {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: none;
    }
    &__images-seven {
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: 
      'photo2 photo2 photo2 photo2 photo22 photo22 photo22 photo22 photo222 photo222 photo222 photo222'
      'photo3 photo3 photo3 photo33 photo33 photo33 photo333 photo333 photo333 photo3333 photo3333 photo3333';
    }

    &__image {
      // margin: 0 0.5rem 0.5rem 0.5rem;
      height: 15rem;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__image-one {
      grid-area: photo1;
    }
    &__image-two {
      grid-area: photo2;
    }   
    &__image-twotwo {
      grid-area: photo22;
    } 
    &__image-twotwotwo {
      grid-area: photo222;
    }
    &__image-three {
      grid-area: photo3;
    }
    &__image-threethree {
      grid-area: photo33;
    }
    &__image-threethreethree {
      grid-area: photo333;
    }
    &__image-threethreethreethree {
      grid-area: photo3333;
    }

    &__video {
      background-color: #15171a;
      border-radius: 50%;
      width: 30px;
      position: absolute;
      height: 30px;
      cursor: pointer;
      opacity: 0.6;
      text-align: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      svg {
        margin-top: 5px;
      }
    }
    &-doc {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      &__link {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 0.8rem;
        }
      }
      &__text {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        font-size: 1.4rem;
        color: #3e4b5d;
        cursor: pointer;
      }
      &__remove {
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }
  }
}
