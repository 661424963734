.c-event-item {
  display: flex;
  align-items: center;
  background-color: $athens-gray;
  padding: 2rem;
  position: relative;
  &__report-mod {
    @include media-breakpoint-down(sm) {
      justify-content: space-between;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &:last-child {
    margin-bottom: 4rem;
  }
  &__img {
    height: 6rem;
    width: 6rem;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: #d9dee2;
    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__empty {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    background-color: #d9dee2;
    //border: 1px solid #3E4B5D;
    //background-image:  url(./../../../images/tmp_file/event.png);
  }
  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    overflow-wrap: anywhere;
  }
  &__info-report-mod {
    // Закомменчено из-за возникшего конфликта (временно)
    // @include media-breakpoint-down(sm) { 
    // align-items: center;
    // margin-left: 1rem;
    // overflow-wrap: anywhere;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &__title {
    font-weight: bold;
    color: $oxford-blue;
    margin-bottom: 4px;
  }
  &__subtitle {
    color: $rolling-stone;
    margin-right: 3px;
  }
  &__date {
    color: $rolling-stone;
    line-height: normal;
  }
  &__requests {
    margin-left: 73%;
    border-radius: 14px;
    background-color: #2f81f3;
    color: #fff;
    padding: 2px 13px;
    cursor: pointer;
    position: absolute;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
  &.with-diplom {
    .c-edit__wrapper {
      margin-left: 0;
    }
  }
  &-diplom {
    position: relative;
    margin-left: auto;
    margin-right: 3.2rem;
    .icon {
      font-size: 2.8rem;
    }
    &:hover {
      .c-event-item-diplom__options {
        opacity: 1;
        visibility: visible;
      }
    }
    &__options {
      position: absolute;
      right: 0;
      padding-top: 0.8rem;
      z-index: 999;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease;
    }
    &__items {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      background-color: $white;
      display: flex;
      flex-direction: column;
      padding: 2rem;
    }
    &__item {
      color: #1f2125;
      white-space: nowrap;
      text-align: left;
      &:not(:last-child) {
        padding-bottom: 0.8rem;
      }
    }
  }
  &-invitations {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    &__btn.e-btn.e-btn--outline {
      border: 1px solid #b0bac4;
      padding: 0.7rem 2rem;
      font-size: 1.4rem;
      font-weight: 400;
    }
    &__accept {
      margin-bottom: 0.4rem;
      position: relative;
      &:hover {
        .c-event-item-invitations__options {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &-options {
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      z-index: 5;
      padding: 2rem;
      width: 22rem;
      position: absolute;
      right: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      margin-top: 0.4rem;
      &-title {
        font-size: 1.4rem;
        font-weight: bold;
      }
      &-list {
        margin-top: 2.1rem;
        margin-bottom: 2rem;
      }
      .e-box {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 1.8rem;
        }
        label {
          margin-left: 1rem;
          margin-bottom: 0;
        }
      }
      &-btn.e-btn.e-btn--filled {
        width: 100% !important;
        max-width: 100%;
        min-width: auto;
        font-size: 1.4rem;
        font-weight: 400;
        padding: 0.5rem 5rem;
        min-height: 3.3rem;
      }
    }
  }
  &-alert {
    margin-left: auto;
    position: relative;
    &:hover {
      .c-event-item-alert__list {
        opacity: 1;
        visibility: visible;
      }
    }
    &__list {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      background-color: $white;
      padding: 2rem 1.5rem;
      position: absolute;
      right: 0;
      z-index: 999;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease;
      width: 26rem;

      p {
        color: #1f2125;
      }
    }
  }
  &.lock {
    flex-shrink: 0;
    .icon-lock {
      font-size: 1.5rem;
      stroke-width: 1px;
      margin-left: auto;
    }
    .c-edit__wrapper {
      margin-left: 1.6rem;
    }
  }
  &.has-notify {
    flex-shrink: 0;
    .icon-chat {
      font-size: 2.2rem;
      stroke-width: 1px;
      margin-left: auto;
      color: transparent;
    }
    .c-edit__wrapper {
      margin-left: 1.6rem;
    }
  }
  &.black-title {
    .c-event-item__title {
      color: $woodsmoke;
    }
  }
  &__protocol {
    justify-content: space-between;
  }
}
