.c-modal-select-role {
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__wrapper {
        max-width: 26rem;
        margin: 0 auto;
        padding: 8rem 0 6.4rem 0;
    }
    &__form {
        margin-top: 5rem;
    }
    &__action-block {
        margin-bottom: 2rem;
        .e-role {
            margin-bottom: 0.5rem;
        }
        &:last-child {
            margin-bottom: 5rem;
        }
        .e-warning {
            font-size: 12px;
        }
    }
    &__next {
        margin-bottom: 3rem;
    }
    &__skip {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}
