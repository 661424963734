.c-modal-create-notify{
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__wrapper {
        padding: 5.5rem 5rem 8rem; 
        @include media-breakpoint-down(sm){
            padding: 4rem;
        }
        @include media-breakpoint-down(xs){
            padding: 2rem;
        }
    }   
    &__form {
        margin-top: 3rem;
        & p{
            min-height: 150px;
        }
        &e-btn--filled {
            width: 100%;
        }
    }
}