.e-label {
    font-size: 1.2rem;
    color: $rolling-stone;
    line-height: 1.6rem;
    margin-bottom: 0.6rem;
}

.e-label-sm {
    font-size: 1.2rem;
    color: $rolling-stone;
    line-height: 1.6rem;
    margin-bottom: 0.6rem;
    margin-right: 1rem;
}

.e-label-right-sm {
    font-size: 1.2rem;
    color: $rolling-stone;
    line-height: 1.6rem;
    margin-bottom: 0.6rem;
    margin-right: 1rem;
    margin-left: 1rem;
}