.e-time-filter{
    &__title {
        color: #15171A;
        font-size: 1.4rem;
        font-weight: 600;
        margin-right: 1rem; }
    &__btn {
        color: #3E4B5D;
        font-size: 1.4rem;
        font-weight: 600;
        position: relative; 
        &:hover .e-time-filter__options {
        opacity: 1;
        visibility: visible; }}
    &__options {
        opacity: 0;
        visibility: hidden;
        z-index: 90;
        position: absolute;
        top: 100%;
        left: 0; }
    &-list {
        background-color: #fff;
        -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        padding: 2.1rem;
        min-width: 24rem;
        margin-top: 1rem; }
    &__wrapper{
        margin-bottom: 10px;
    }
}