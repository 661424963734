.c-modal-report__head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-image {
        width: 80px;
        height: 80px;
        margin-right: 14px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.modal__title {
    text-align: left;
}