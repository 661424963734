.c-menu {
    max-width: 18rem;
    height: 100%;
    margin-left: 10px;
    &__body{
        background-color: $irskom-darkgray;
    }
    &__wrapper {
      margin-top: 2rem;
    }
    &__list {
      padding-top: 1.6rem;
      padding-bottom: 0.7rem;
      &:first-child {
        padding-top: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #D4DBDF;
      }
    }
    &__item {
      margin-bottom: 1.1rem;
    }
    &__selected{
        text-decoration: underline;
        font-weight: bold;
    }
    &__link {
      display: flex;
      align-items: center;
    }
    &__ico {
      margin-left: 1.6rem;
      font-size: 1.6rem;
    }
    &__txt {
      color: #FFFFFF;
      min-width: 100px;
    }
    &__notify{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: bold;
      color: $white;
      height: 2rem;
      min-width: 2rem;
      border-radius: 50%;
      background-color: $dodger-blue;
      float: right;
      margin-left: 15px;
    }
    & a {
        text-decoration: none !important;
    }
  }
