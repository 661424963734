﻿.confirmation {
    padding: 20px 50px;
}

.confirmation-actions {
    display: flex;
    justify-content: space-between;
    
    & button {
        min-width: 20rem;
    }
}
