.e-btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 1.5rem;
	min-width: 26rem;
	font-weight: bold;
    border-radius: 10px;
	transition: background-color 0.3s ease;
	border: none;
	&--filled {
		background-color: $irskom-gray;
		color: $white;
		&:hover , &:focus , &:active {
				background-color: $irskom-darkgray;
				outline: inherit;
		}
	}
	&--md {
		max-height: 5rem;
	}
	&--clear {
		color: $irskom-gray;
	}
	&--mt5 {
		margin-top: 5rem;
	}
	&--red {
    	background-color: #F10F2A;
    	color: white;
    	&:hover, .e-btn--red:focus, .e-btn--red:active {
    	  	background-color: #F10F2A;
			outline: inherit;
		}
	}
	&--confirm {
		margin-right: 2rem;
		max-width: 100px;
		min-width: 150px;
		height: 4rem;
	}
}

.e-btn-sm {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0.5rem;
	width: 15rem;
	border-radius: 10px;
	transition: background-color 0.3s ease;
	border: none;
	&--filled {
		background-color: $irskom-gray;
		color: $white;
		&:hover , &:focus , &:active {
			background-color: $irskom-darkgray;
			outline: inherit;
		}
	}
	&--md {
		max-height: 5rem;
	}
	&--clear {
		color: $irskom-gray;
	}
	&--mt5 {
		margin-top: 5rem;
	}
	&--red {
		background-color: #F10F2A;
		color: white;
		&:hover, .e-btn--red:focus, .e-btn--red:active {
			background-color: #F10F2A;
			outline: inherit;
		}
	}
	&--confirm {
		margin-right: 2rem;
		max-width: 100px;
		min-width: 150px;
		height: 4rem;
	}
}

