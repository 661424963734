html {
	height: 100%;
	font-size: 10px;
}
body {
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;
	letter-spacing: .015rem;
}
img{
	max-width: 100%;
	height: auto;
}
