*:focus{
  outline: none !important;
  box-shadow: none !important;
}

.l-wrapper{
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	@include media-breakpoint-down(sm) {
		overflow: visible;
	}
}

button {
	background: transparent;
	border: none;
	padding: 0;
}
.form-group {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 3rem;
	@include media-breakpoint-down(sm) {
		margin-bottom: 2rem;
		width: 100%;
	}
	&.error {
		.e-input {
			border: 1px solid #F10F2A;
		}
		.e-textarea{
			border: 1px solid #F10F2A;
		}
	}
	&__error {
		color: #F10F2A;
		font-size: 1.2rem;
		line-height: 1.6rem;
		position: absolute;
		bottom: -2rem;
		left: 0;
	}
}

.form-control {
	position: relative;
}

.ui-block {
	margin-bottom: 2rem;
}

.right-side {
	padding-top: 6rem;
	max-width: 18rem;
	width: 100%;
	margin-left: auto;
	//@include media-breakpoint-down(md) {
    //display: none;
  //}
  &.w280 {
    max-width: 28rem; }
}
.left-side {
	padding-top: 2.2rem;
}
.main-content {
	padding-top: 1.9rem;
}

.page-scroll-off {
	position: fixed;
} // T4308

.mobile-burger {
	@include media-breakpoint-down(md) {
    display: none;
	}
	&.show {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		min-height: 590px;
		background-color: $white;
		overflow-y: scroll;
		z-index: 99999;
		.mobile-burger__head {
			display: flex;
		}
	}
	&__head {
		background-color: $athens-gray;
		padding: 1.45rem 1.3rem;
		display: none;
	}
	&__btn {
		height: 3.1rem;
		width: 3.1rem;
		display: flex;
		align-items: center;
		span {
			width: 100%;
			height: 0.3rem;
			background-color: $oxford-blue;
			border-radius: 0.8rem;
			position: relative;
			display: flex;
			transform: rotate(-45deg);
			&:after{
				content: '';
				position: absolute;
				width: 100%;
				height: 0.3rem;
				background-color: $oxford-blue;
				border-radius: 0.8rem;
				left: 0;
				right: 0;
				bottom: 0;
				transform: rotate(-90deg);
			}
		}
	}
	&__content {
		@include media-breakpoint-down(md) {
			padding: 1rem;
		}
	}
}
