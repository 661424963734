.c-modal-create-event {
    &::after {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0.8;
        background: #3e4b5d;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__wrapper {
      padding: 5.5rem 16rem 4.5rem;
      position: relative;
      @include media-breakpoint-down(sm) {
        padding: 4rem;
      }
      @include media-breakpoint-down(xs) {
        padding: 2rem;
      }
    }
    &__form {
        margin-top: 4rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
    &__img {
        height: 8rem;
        width: 8rem;
        border-radius: 50%;
        background-color: #D9DEE2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; 
        & .icon {
            font-size: 2.5rem; }
    }
    &__desc {
        font-size: 1.4rem;
        color: #3E4B5D;
        margin-top: 1rem;
        letter-spacing: -0.7px; }
    &__btn {
        margin-top: 3rem; }
}