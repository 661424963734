.c-community-sidebar {
  @include media-breakpoint-down(md) {
    display: none;
  }

  &__head {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &.show {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 50;
    background-color: $white;
  }

  &__block-gray {
    background-color: $athens-gray;
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
  }

  &__content {
    @include media-breakpoint-down(md) {
      padding: 2rem;
    }
  }

  &__btn:hover {
    .c-event-item-invitations__options {
      opacity: 1;
      visibility: visible;
    }
  }

  &__btn.e-btn--outline {
    margin-bottom: 2rem;
    min-height: 5rem;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__title {
    font-weight: bold;
  }

  &-members {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-organizer {
    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
    }

    &-user {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &__name {
        color: $oxford-blue;
      }

      &__img {
        height: 1.8rem;
        width: 1.8rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1rem;
        margin-top: 0.5rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &-admins {
    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
    }

    &-user {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &__img {
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1rem;
        min-width: 4rem;
      }

      &__img--empty {
        height: 4rem;
        width: 4rem;
        max-height: 4rem;
        min-width: 4rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1rem;
        background-image: url('../../../images/tmp_file/profile.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &-user__name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-menu {
    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
    }

    &-item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &__text {
        color: $oxford-blue;
      }
      &-user {
        display: flex;
        align-items: center;
        transition: all .3s ease;
        &:not(:last-child){
          margin-bottom: 1rem;
        }
        &__img {
          height: 4rem;
          width: 4rem;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 1rem;
          min-width: 4rem;
        }
        &__img--empty {
          height: 4rem;
          width: 4rem;
          max-height: 4rem;
          min-width: 4rem;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 1rem;
          background-image: url('../../../images/tmp_file/profile.png');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }      
      &-user:hover {
        cursor: pointer;
        opacity: .7;
        transition: all .3s ease;
      }

      .icon {
        margin-right: 1.6rem;
        font-size: 1.5rem;
        fill: transparent;
        stroke-width: 1px;
      }

      .icon-use {
        stroke-width: 0;
      }
    }
  }

  &__wrapper {
    position: relative;

    &:hover {
      .c-community-sidebar__child-options {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__child-options {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 5;
    padding: 2rem;
    width: 22rem;
    position: absolute;
    left: -5rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    &-title {
      font-size: 1.4rem;
      font-size: bold;
    }

    &-list {
      margin-top: 2.1rem;
      margin-bottom: 2rem;
    }

    .e-box {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1.8rem;
      }

      label {
        margin-left: 1rem;
        margin-bottom: 0;
      }
    }

    &-btn.e-btn.e-btn--filled {
      width: 100% !important;
      max-width: 100%;
      min-width: auto;
      font-size: 1.4rem;
      font-weight: 400;
      padding: 0.5rem 5rem;
      min-height: 3.3rem;
    }
  }

}